<template>
  <v-menu
    offset-y
    left
    bottom
    :close-on-content-click="false"
    v-model="menuOpen"
  >
    <template #activator="{ on, attrs }">
      <eva-button
        dense
        outlined
        v-on="on"
        v-bind="attrs"
        v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.buttonTooltip')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m9 4l2.5 5.5L17 12l-5.5 2.5L9 20l-2.5-5.5L1 12l5.5-2.5zm0 4.83L8 11l-2.17 1L8 13l1 2.17L10 13l2.17-1L10 11zM19 9l-1.26-2.74L15 5l2.74-1.25L19 1l1.25 2.75L23 5l-2.75 1.26zm0 14l-1.26-2.74L15 19l2.74-1.25L19 15l1.25 2.75L23 19l-2.75 1.26z"
          />
        </svg>
      </eva-button>
    </template>

    <v-card
      width="400px"
    >
      <v-card-text>
        <template v-if="showLoader">
          <v-skeleton-loader
            v-for="i in 7"
            :key="i"
            type="text"
            class="mb-0"
            :loading="false"
            :width="`${
              Math.floor(Math.random() * 50) + 50
            }%`"
          />
        </template>
        <v-card
          outlined
          flat
          class="pa-2"
          v-else
          style="max-height: 300px; height: 300px; overflow: scroll;"
        >
          <katex-md
            :expr="feedbackSuggestion"
          />
        </v-card>
      </v-card-text>
      <v-card-actions class="px-4 pt-0">
        <eva-button
          dense
          @click="generateFeedback"
          v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.regenerate')"
        >
          <v-icon small>
            mdi-refresh
          </v-icon>
        </eva-button>
        <v-spacer />
        <keyboard-tooltip
          :key-name="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.key')"
          :label-after="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.tooltip')"
        >
          <eva-button
            primary
            :label="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.label')"
            dense
            @click="insert"
          />
        </keyboard-tooltip>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>

import { ref, watch } from "vue"
import KatexMd from "@/components/katex-md.vue"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import KeyboardTooltip from "@/components/shared/keyboard_tooltip.vue"
import { onKeyStroke } from "@vueuse/core"

export default {
  name: "GenerateFeedbackMenu",
  components: { KeyboardTooltip, KatexMd },
  props: {
    answer: { type: Object, required: true },
  },
  emits: ["insert"],
  setup(props, { emit }) {
    const showLoader = ref(true)
    const menuOpen = ref(false)
    const feedbackSuggestion = ref("")

    const generateFeedback = () => {
      showLoader.value = true
      axios.post(props.answer.links.feedback_suggestion).then((response) => {
        showLoader.value = false
        feedbackSuggestion.value = jsonApii.getData(response.data).content
      })
    }

    const insert = () => {
      if (!feedbackSuggestion.value) return
      emit("insert", feedbackSuggestion.value)
      menuOpen.value = false
    }

    onKeyStroke("Enter", (e) => {
      e.preventDefault()
      if (menuOpen.value) {insert()}
    })

    watch(menuOpen, (value) => {
      if (value) {
        feedbackSuggestion.value = ""
        generateFeedback()
      }
    })
    return {
      showLoader,
      menuOpen,
      generateFeedback,
      feedbackSuggestion,
      insert,
    }
  },
}
</script>
