<template>
  <v-card
    v-if="answer"
    flat
    outlined
    color="transparent"
  >
    <v-card-text class="pb-0">
      {{ label }}
      <v-btn-toggle
        v-model="answer.manual_score"
        :mandatory="false"
        @change="save"
        dense
      >
        <v-btn
          v-for="(grade, index) in grades"
          :value="grade"
          :key="index"
        >
          {{ displayGrade(grade) }}
        </v-btn>
        <v-btn :value="customGrade != null && customGrade">
          <v-text-field
            flat
            v-model="customGrade"
            @click.stop
            @change="saveCustomGrade"
            hide-details
            class="input-in-btn pt-1 pb-2 no-border"
            type="number"
            :placeholder="
              $t(
                'evalmee.home.teacher.openQuestionGrader.customGrade.placerholder'
              )
            "
            :rules="gradesRules()"
            :v-tooltip="
              $t(
                'evalmee.home.teacher.openQuestionGrader.customGrade.tooltip',
                { maxGrade }
              )
            "
            ref="customGrade"
            dense
          />
        </v-btn>
      </v-btn-toggle>

      <v-icon
        color="green"
        class="fixed_width_icon"
      >
        {{ toGrade ? "" : "check" }}
      </v-icon>

      <v-textarea
        class="mt-4"
        v-model="comment"
        :label="
          (comment || '') === ''
            ? null
            : $t('evalmee.home.teacher.openQuestionGrader.addComment.label')
        "
        :placeholder="
          $t('evalmee.home.teacher.openQuestionGrader.addComment.placeholder')
        "
        auto-grow
        rows="1"
        hide-details
        flat
        solo
        filled
      >
        <template #append>
          <generateFeedbackMenu
            v-if="getFeature('feedback_per_answer_ai').value"
            :answer="answer"
            @insert="(content) => comment = [(comment || '') + content].join(`\n`)"
          />
        </template>
      </v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="save"
        small
        v-if="comment !== answer.comment"
        color="primary"
        class="mr-2"
      >
        {{ $t("evalmee.home.teacher.openQuestionGrader.saveComment") }}
      </v-btn>
    </v-card-actions>

    <turnitin-answer-report
      v-if="quiz.similarity_tool_enabled"
      class="mt-4"
      :answer="answer"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex"
import TurnitinAnswerReport from "./similarity_check/turnitin_answer_report.vue"
import GenerateFeedbackMenu from "@/components/questions/GenerateFeedbackMenu.vue"
import getFeatureMixin from "@/components/shared/get_feature_mixin"

export default {
  name: "OpenQuestionGrader",
  components: { GenerateFeedbackMenu, TurnitinAnswerReport },
  mixins: [getFeatureMixin],
  props: {
    answer: { type: Object },
    currentUser: { type: Object },
    label: { type: String },
    quiz: { type: Object },
    question: { type: Object },
  },
  data: () => ({
    comment: "",
    customGrade: null,
  }),
  computed: {
    toGrade() {
      if (this.answer.score === this.customGrade && this.answer.score != null)
        return false
      if (
        parseFloat(this.customGrade) > 0 &&
        !this.validCustomGrade(this.customGrade)
      )
        return true

      return this.answer?.require_manual_grading
    },
    grades() {
      return (
        this.gradeScales.find((gs) => gs.maxGrade === this.maxGrade)?.scale ||
        this.defaultScale
      )
    },
    gradeScales() {
      return [
        {
          maxGrade: 1,
          scale: [...this.wrongAnswerScore, 0, 0.25, 0.5, 0.75, 1],
        },
        {
          maxGrade: 1.5,
          scale: [...this.wrongAnswerScore, 0, 0.5, 1, 1.5],
        },
        {
          maxGrade: 2,
          scale: [...this.wrongAnswerScore, 0, 0.5, 1, 1.5, 2],
        },
        {
          maxGrade: 3,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3],
        },
        {
          maxGrade: 4,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3, 4],
        },
        {
          maxGrade: 5,
          scale: [...this.wrongAnswerScore, 0, 1, 2, 3, 4, 5],
        },
        {
          maxGrade: 6,
          scale: [...this.wrongAnswerScore, 0, 2, 4, 6],
        },
      ]
    },
    defaultScale() {
      return [
        ...this.wrongAnswerScore,
        0,
        Math.round((this.maxGrade * 100) / 2) / 100,
        this.maxGrade,
      ]
    },
    maxGrade() {
      return this.question?.attributes?.coefficient || 0
    },
    wrongAnswerScore() {
      if (this.quiz?.score_for_wrong_answer == null) return []
      if (this.quiz?.score_for_wrong_answer === 0) return []

      return [this.quiz.score_for_wrong_answer]
    },
  },
  methods: {
    ...mapActions(["updateAnswer", "newAppNotification"]),
    save() {
      this.answer.comment = this.comment
      this.updateAnswer(this.answer)
        .then(() =>
          this.newAppNotification({
            message: this.$t(
              "evalmee.home.teacher.openQuestionGrader.toast.gradeSaved"
            ),
            type: "success",
          })
        )
        .catch((error) =>
          this.newAppNotification({
            message: this.$t(
              "evalmee.home.teacher.openQuestionGrader.toast.invalidGrade",
              { error }
            ),
            type: "error",
          })
        )
    },
    saveCustomGrade() {
      this.answer.manual_score = this.customGrade
      this.save()
    },
    updateCustomGrade() {
      if (this.grades.includes(this.answer.manual_score)) {
        this.customGrade = null
        this.$refs.customGrade?.validate()
        this.$refs.customGrade?.blur()
        return
      }

      this.customGrade = this.answer.manual_score
    },
    validCustomGrade(grade) {
      return this.gradesRules()
        .map((rule) => rule(grade) === true)
        .reduce(Boolean)
    },
    displayGrade(grade) {
      return `${grade}`.replace(".", ",")
    },
    gradeValidator(grade) {
      if ((parseFloat(grade) || 0) <= this.maxGrade) return true

      return this.$t(
        "evalmee.home.teacher.openQuestionGrader.addComment.errors.maxGrade",
        { maxGrade: this.maxGrade }
      )
    },
    gradesRules() {
      return [this.gradeValidator]
    },
  },
  watch: {
    answer: {
      handler() {
        this.comment = this.answer.comment
        this.updateCustomGrade()
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.fixed_width_icon {
  width: 24px;
}

.input-in-btn {
  width: 60px;
}
</style>
